import React, { useState } from 'react';
import {
  CForm,
  CFormLabel,
  CFormInput,
  CButton
} from '@coreui/react';
import AddItemGallery from 'src/components/gallery/AddItemGallery';
import AxiosInstance from 'src/components/Axios';
import BasicModal from 'src/components/Modal';
import 'react-toastify/dist/ReactToastify.css';
import GalleryList from 'src/components/gallery/GalleryList';
import PermissionsNoPage, { Permissions } from "src/components/Permissions";


const GalleryObjectForm = ({ user, data, setData, popup, galleryObject, setGalleryObject }) => {
  if (!Permissions(user, 'Gallery', 'EDIT_GALLERY')) {
    return <PermissionsNoPage />;
  }
  const [name, setName] = useState((data != undefined) ? data.name : '');
  const [sort, setSort] = useState((data?.sort != undefined) ? data.sort : 0);

  const handleSubmit = event => {
    event.preventDefault();
    const article = {
      name: name,
      sort: parseInt(sort),
    };

    if (data != undefined) {
      const response = AxiosInstance.put('/galleries/' + data.id, article).then((response) => {
        if (response.status == 200) {
          popup.toastifySuccess('Галерею оновлено');
          setData(response.data);
          updateGalleryObjects(data.id);
        }
      });
    } else {
      const response = AxiosInstance.post('/galleries', article).then((response) => {
        // console.log(response);
        if (response.status == 201) {
          popup.toastifySuccess("Галерею створено")
          setData(response.data);
        }
      });
    }
  }

  const [showModal, setShowModal] = useState(false);
  const [updateLists, setUpdateLists] = useState(true);

  const actionShowModal = (rez, isUpdate = false) => {
    setShowModal(rez);
    if (!rez && isUpdate)
      setUpdateLists(true);
  }

  const updateGalleryObjects = (gallery_id) => {
    AxiosInstance.get(`/galleries/${gallery_id}`).then((responce) => {
      if (responce.status == 200) {
          const filtred = galleryObject.filter(gallery => gallery.id !== gallery_id);
          setGalleryObject([...filtred,  responce.data]);
      }
    });
  }

  return (
    <>
      <CForm onSubmit={handleSubmit}>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Назва галереї</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput1">Сортування</CFormLabel>
            <CFormInput type="number" step={1} min={0} id="exampleFormControlInput2" value={sort} onChange={(e) => setSort(e.target.value)} autoComplete="off" />
        </div>
        <CButton type="submit" color="success">Зберегти</CButton>
      </CForm>
      <hr />
      {(data !== undefined) &&
        <div className='row'>
          <div className='col-12'>
            <BasicModal
              show={showModal}
              actionShowModal={actionShowModal}
              title={`Додати  - ${data.name}`}
              btn_name={<>Додати об'єкт</>}
              content={
                <AddItemGallery toastifySuccess={popup.toastifySuccess} parent_id={data.id} actionShowModal={actionShowModal} />
              }
            />
          </div>
          <div className='col-12 mt-2'>
            <GalleryList 
                galleryObject = {galleryObject} 
                setGalleryObject={setGalleryObject}
                updateGalleryObjects = {updateGalleryObjects}
                gallery_id={data.id} 
                updateLists={updateLists} 
                setUpdateLists={setUpdateLists} />
          </div>
        </div>
      }
    </>
  );
};

export default GalleryObjectForm;