import { PropaneTankSharp } from "@mui/icons-material";
import { useEffect, useState } from "react";
// import AxiosInstance from "../Axios";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AxiosInstance from "src/components/Axios";


const urlApi = '/languages';

const LangugesPanel = ({ data, setLaguage, language, setMyData }) => {
   const navigate = useNavigate();

   // console.log("data", data);
   const [languagesList, setLaguagesList] = useState([]);
   // const [language, setLaguage] = useState('');
   // const [language, setLaguage] = useState((data != undefined)? data.language.key: 'ua');
   const [translations, setTranslations] = useState((data != undefined && data.translations != undefined) ? data.translations : false);
   //флаг для search
   let search = true
   const getLangs = () => {
      AxiosInstance.get(`${urlApi}?page=1`).then((response) => {
         setLaguagesList(response.data['hydra:member'])
      })
   }
   //зак
   useEffect(() => {
      setLaguage((data != undefined && data.language != undefined) ? data.language.key : 'ua');
      setTranslations(prev => data.translations)
      getLangs();
   }, [data]);



   const CreateTranslate = (e, l) => {

      const data_response = {
         // attribute_items: data.attribute_items,
         budget: data.budget,
         // category: data.category,
         content: '',
         //  data.content,
         curency: data.curency,
         dateEnd: data.dateEnd,
         dateStart: data.dateStart,
         financed: data.financed.length > 0 ? data.financed.map(finance => `/api/accounts/${finance.id}`) : [],
         mainMedia: data.mainMedia ? `/api/media_objects/${data.mainMedia.id}` : '',
         name: '',
         //  data.name,
         // pageInfos: data.pageInfos,
         partners: data.partners.length > 0 ? data.partners.map(partner => `/api/accounts/${partner.id}`) : [],
         // scannings: data.scannings,
         shortName: '',
         // data.shortName,
         shortDescription: '',
         //  data.shortDescription,
         // slider: data.slider ? data.slider : false,
         ...(data.slider ? { slider: data.slider } : {}),
         status: data.status,
      };
      data_response['language'] = `/api/languages/${l.id}`;
      data_response['translation'] = `/api/projects/${data.id}`;
      const response = AxiosInstance.post(`/projects`, data_response).then((response) => {
         console.log('response', response)
         if (response.status === 201) {

            return navigate(`/projects/update/${response.data.id}`);
         }
      }).catch((error) => {
         console.error("Error creating translation:", error);
      });

   }

   // console.log('data', data)
   // console.log('languageList', languagesList)
   // console.log(data.pageInfos.map(elem => `/api/page_infos/${elem.id}`))
   return (
      <div>
         {/* <div>: </div> */}
         <div>
            <ul>
               {
                  (data != undefined && data.translation != undefined && data.translation && data.translation.id != undefined) ?
                     <>
                        <h4>Переклад: {language}</h4>
                        <Link to={`/projects/update/${data.translation.id}`}>Повернутися до UA</Link>
                     </>
                     :
                     <>
                        <h4>Переклад: {language}</h4>
                        {/* <li className='btn btn-secondary text-white'>{language}</li> */}
                        {languagesList.map((l) => {
                           // let search = true;
                           search = true
                           // console.log('translations', translations);
                           if (l.key != language) {
                              // if (translations !== false) {
                              if (translations?.length > 0) {
                                 let t = translations.filter(t => t.language.key === l.key)
                                 if (t.length > 0) {
                                    // console.log('search', search)
                                    return (<Button key={l.id} variant="outline-secondary ml-5" ><Link to={`/projects/update/${t[0].id}`} >{l.key}</Link></Button>);
                                 }
                                 else
                                    search = false;

                              } else {
                                 search = false;

                              }
                           }
                           if (!search) {
                              return (<Button key={l.id}
                                 variant="outline-secondary mr-5"
                                 onClick={(e) => { CreateTranslate(e, l) }}
                              >
                                 додати  <b>{l.key}</b>
                              </Button>);
                           }

                        })}
                     </>
               }
            </ul>
         </div>

      </div>


   );

}

export default LangugesPanel;