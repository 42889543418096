import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import AxiosInstance from '../Axios';
import { CListGroup, CListGroupItem, CButton } from '@coreui/react';
import { confirmAlert } from 'react-confirm-alert';
import Badge from 'react-bootstrap/Badge'
import { Link } from 'react-router-dom';

// import { Link } from 'react-router-dom';

// Додавання категорій до продукту
const AddCategories = (props) => {
    // console.log("props", props);
    // props.product_id - id-продукту

    // Категорії, які додані до продукту
    const [catItem, setCatItem] = useState([]);
    // Категорії, які додані до продукту, записані у форматі PUT (`api/categories/id`) для відправлення
    const [oldCat, setOldCat] = useState([]);
    // Вибрана категорія з пошуку
    const [selectedOption, setSelectedOption] = useState(null);
    // Текст для popup
    const popupTextAddSuccess = props.type === 'objects' ? "Колекцію додано" : "Категорію додано";
    const popupTextDeleteSuccess = props.type === 'objects' ?  "Колекцію видалено" : "Категорію видалено";

    let i = 0;
    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
    };
    const getCategoryWithParents = (result) => {
        let n = '';
        if(result.parent != undefined){
            n += ` -> ${result.parent.name}`;
            n += getCategoryWithParents(result.parent)
        }
        return n;
    }
    // Завантаження всіх категорій для вибору з випадаючого списку
    const loadOptions = async (inputValue) => {
        return await AxiosInstance.get(`/categories?type=${props.type}`, { headers: { 'accept': 'application/json' }, params: {
            name: inputValue,
            'g[0]': 'cat:read',
            'g[1]': 'cat:parent',

        } }).then((response) => {

            return response.data.map((result) => {
                let parent = getCategoryWithParents(result);
                let n = result.name + parent;
                
                return {
                    label: n,
                    value: result.id,
                }
            });
        });
    };

    // Запит за категоріями, які вже додані до продукту
    const getParent = async () => {
        AxiosInstance.get(`/categories`, { headers: { 'accept': 'application/json' }, params:{
            [`${props.type}.id`]: props.data_id,
            'g[0]': 'cat:read',
            'g[1]': 'cat:parent',
        } }).then((response) => {
            // console.log("response get products", response);
            setCatItem(response.data)
        });
    }
    useEffect(() => {
        getParent();
    }, [props]);

    // console.log("catItem для продукту", catItem)

    // Заповнити категорії, які додані до продукту, записані у форматі PUT (`api/attributs/id`) для відправлення
    useEffect(() => {
        if (catItem.length > 0) {
            let oldCategories = [];
            // Наповнити масив з категорями, перетвеними у формат для API
            catItem.map(el => oldCategories.push(`api/categories/${el.id}`));
            // console.log("oldCategories", oldCategories);
            setOldCat(oldCategories);
        }
        // console.log("catItem.categories", catItem);
    }, [catItem]);

    // Додати нову категорію
    const handleSave = () => {
        // Перевірка, чи вибрана категорія вже належить до даного продукту
        let enters = catItem.length > 0 ? catItem.some(el => el.id == selectedOption.value) : false;
        if (enters) {
            alert(`Вибрана ${props.type === 'objects' ? 'колекція' : 'категорія'} вже належить до даного продукту`);
        } else {
            // Вибрана нова категорія
            let newCategories = [`api/categories/${selectedOption.value}`];
            // Об'єднати старі категорії (якщо є) і нову категорію
            let categories = oldCat.length > 0 ? oldCat.concat(newCategories) : newCategories;
            // Дані, які підуть із запитом
            let article = {
                category: categories
            };

            // Оновити категорії для продукту
            AxiosInstance.put(`/${props.type}/${props.data_id}`, article).then((response) => {
                // console.log("response put", response);
                if (response.status == 200) {
                    // Popup про успішні зміни
                    props.toastifySuccess(popupTextAddSuccess);
                    // alert("Дані оновлено");
                    getParent();
                }
            });
        };
    };

    // Видалити категорію від продукту
    const deleteCategory = (id) => {
        let categories = [];
        // Відфільтрувати категорію, яку потрібно видалити
        let filterCategories = catItem.filter(el => el.id !== id);
        // Наповнити масив з категорями, перетвеними у формат для API
        filterCategories.map(el => categories.push(`api/categories/${el.id}`));
        // Дані, які підуть із запитом
        let article = {
            category: categories
        };

        // Оновити категорії для продукту
        AxiosInstance.put(`/${props.type}/${props.data_id}`, article).then((response) => {
            // console.log("response put", response);
            if (response.status == 200) {
                // alert("Дані оновлено");
                // Popup про успішні зміни
                props.toastifySuccess(popupTextDeleteSuccess);
                getParent();
            }
        });
    };

    // Видалити категорію від продукту
    function Deleted(row) {
        confirmAlert({
            title: 'Ви впевнені що хочите видалити цей пункт ?',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Так',
                    onClick: () => {
                        // console.log("delete row id", row);
                        deleteCategory(row);
                    }
                },
                {
                    label: 'Ні',
                    onClick: () => {
                        return;
                    }
                }
            ]
        });
    };

    return (
        <>
            <div className="row">
                <div className="col">
                    <h3>
                    {props.type === 'objects' 
                        ? 'Колекції (категорії)'
                        : 'Категорії'}
                    </h3>
                </div>
                <div className="col d-flex">
                    <div className='w-100'>
                        <AsyncSelect
                            cacheOptions
                            loadOptions={loadOptions}
                            defaultOptions
                            value={selectedOption}
                            onChange={handleChange}
                            className="flex-grow-1  me-3"
                            placeholder={props.type === 'objects' ? 'Додати колекцію' : 'Додати категорію'}
                        />
                        <Link className='d-block pt-2 pb-2' to={'/category/create'}>
                            {props.type === 'objects' ? "Створити колекцію" : "Створити категорію" }
                        </Link>
                    </div>
                    <CButton onClick={handleSave} className="mb-2 w-25 h-50" color="secondary">+ Зберегти</CButton>
                </div>
            </div>

            {/* Список з доданими категоріями */}
            {
                (catItem.length > 0) ?
                    <CListGroup className="mb-3">
                        {
                            catItem.map((v, k) => {
                                i++;
                                let p = getCategoryWithParents(v);
                                return (
                                    <CListGroupItem key={k} className="d-flex justify-content-between align-items-center">
                                        {/* <Link to={`/products/update/${v.id}`} >{`${i})`} {v.name}</Link> */}
                                        <div>{`${i})`} <strong>{v.name}</strong> <Badge variant="light" className='bg-light text-secondary'> {p} </Badge></div>
                                        <CButton color="danger" variant="outline" onClick={() => { Deleted(v.id) }}>
                                            Видалити
                                        </CButton>
                                    </CListGroupItem>
                                )
                            })
                        }
                    </CListGroup>
                    :
                    <p className="ml-3">
                    {props.type === 'objects' 
                        ? 'Колекцій не знайдено'
                        : 'Категорій не знайдено'}
                    </p>
            }
        </>
    );
}

export default AddCategories;