import { useState, useEffect } from "react";
import AxiosInstance from "../Axios";
import 'react-toastify/dist/ReactToastify.css';
import { CButton, CFormSelect, CFormInput, CFormTextarea } from "@coreui/react";
import { cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {  BsSortNumericDown } from 'react-icons/bs';
import { sortObj } from "../store/sortObj";
import React from "react";
import BasicModal from "../Modal";
import ModelsObjectEdit from "./ModelsObjectEdit";

const ModelsObject = ({ dataId, currentModels, popup }) => {

const [modelType, setModelType] = useState('');
const [modelsObject, setModelsObject] = useState([]);

const [selectedData, setSelectedData] = useState(null); // Вибрана модель для редагування
const [showModalItemUpdate, setShowModalItemUpdate] = useState(false); // Показати модальне вікно для оновлення моделі
const [indexUpdate, setIndexUpdate] = useState(0);

// Функція для відкривання модального вікна та оновлення даних
const actionShowModalItemUpdate = (rez) => {
  setShowModalItemUpdate(rez);
  if (!rez) {
    setSelectedData(rez);
  };
};

// Відкривати вибрану модель для редагування
useEffect(() => {
  if (selectedData) {
    setShowModalItemUpdate(true);
  };
}, [selectedData]);

useEffect(()=>{
    if(currentModels !== "" && currentModels !== null) {
        setModelsObject(currentModels);
    }
}, [])

function addModel() {
    if (modelType !== 'Тип моделі' && modelType !== '' ){
        setModelsObject([...modelsObject, {type: modelType, data: '', sort: modelsObject.length + 1 }])
    }
    else {
        popup.toastifyWarning('Оберіть тип');
    }
}

function handleModelData(e){
    const { name, value } = e.target;
    let rowkey = parseInt(e.target.attributes.rowkey.value);
    setModelsObject((prevData) => prevData.map((item, index) => index === rowkey ? { ...item, [name]: value } : item));
}

function removeModel(id) {
    const filtredModels = modelsObject.filter((model, index) => index !== id);
    setModelsObject(filtredModels);
  }

function submitModels(e) {
    e.preventDefault();
    AxiosInstance.put(`/objects/${dataId}`, {modelsObjects: modelsObject}).then(()=>{
        popup.toastifySuccess('Дані збережено');
    });
}

  return (
    <div className="models-object">
    <div className="mb-4 d-flex align-items-center gap-3">
            <CFormSelect
                aria-label="Default select example"
                onChange={(e) => setModelType(e.target.value)}
                defaultValue={modelType}
                required
                options={[
                  { label: 'Тип моделі', value: '' },
                  { label: 'sketchfab', value: 'Sketchfab' },
                  { label: 'nira', value: 'Nira' },
                  { label: 'other', value: 'Other' }
                ]}
                style={{maxWidth: '500px', height: '100%'}}
            />
          <button type="button" onClick={addModel} className="mb-2 mt-2 btn btn-secondary"> Додати модель +</button>
    </div>
    <form action="submit" onSubmit={submitModels}>
        {modelsObject.length > 0 && sortObj(modelsObject, 'sort').map((model, index) => {
            return <React.Fragment key={index}>
                     <div className="mb-3 d-flex align-items-center gap-1">
                         <CFormInput style={{maxWidth: '500px', height: '100%'}} type="text" id="exampleFormControlInput1" value={model.type} disabled autoComplete="off" />
                         <small className='SortPunkt'><BsSortNumericDown /> - {model.sort}</small>
                         <CButton color='secondary' onClick={() => {setSelectedData(model); setIndexUpdate(index)}} style={{ cursor: "pointer" }} title="Редагувати">
                            🖊 редагувати
                          </CButton>
                         <CButton className="ms-2" color="danger" variant="outline" onClick={() => {removeModel(index)}}>
                             <CIcon icon={cilTrash} customClassName="nav-icon" height={20} />
                         </CButton>
                     </div>
                    <CFormTextarea type="text" id="exampleFormControlInput2" className="mb-5" name="data" value={model.data} rowkey={index} onChange={handleModelData} autoComplete="off" />
            </React.Fragment>
        })}
         <CButton type="submit" color="success">Зберегти</CButton>
       </form>
      {/* Модальне вікно з вибраною моделью */}
      {(selectedData && showModalItemUpdate) &&
        <div style={{ display: 'none' }}> {/* приховати кнопку відкритого модуля */}
          <BasicModal
            show={showModalItemUpdate}
            actionShowModal={actionShowModalItemUpdate}
            title={`Редагувати модель`}
            btn_name={<>🖊 редагувати</>}
            content={
              <ModelsObjectEdit 
                toastifySuccess={popup.toastifySuccess} 
                data={selectedData} 
                setModelsObject = {setModelsObject} 
                actionShowModal={actionShowModalItemUpdate}
                indexUpdate = {indexUpdate}
                />
            }
          />
        </div>}
    </div>
  );
};

export default ModelsObject;