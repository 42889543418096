import React, { useEffect, useRef, useState } from 'react';
import {
  CForm,
  CFormLabel,
  CFormInput,
  CButton, CRow, CCol, CCard, CCardHeader, CCardBody,
  CFormTextarea
} from '@coreui/react';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import axios from "axios";
import Select from 'react-select'
import { DataGrid } from '@mui/x-data-grid';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {
  cilPen,
  cilPlus,
  cilTrash
} from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import AxiosInstance from 'src/components/Axios';
import BasicModal from 'src/components/Modal';
import MediaFrom from '../media/MediaFrom';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { Editor } from '@tinymce/tinymce-react';

const initialValues = {
  // id: "",
  name: "",
  content: "",
  media: "",
  data: [],
  // slider: "",
  btnUrl: "",
  mediaUrl: "",
  orders: 0,
};

const SlideForm = ({ id, parent_id, actionShowModal, data, toastifySuccess }) => {

  const [values, setValues] = useState(initialValues);
  const [media, setMedia] = useState();
  const [mediaUrl, setMediaUrl] = useState(false);
  const editorRef = useRef(null);
  // Ключ для Editor
  const apiKey = process.env.REACT_APP_TINYMCE_API_KEY;

  useEffect(() => {
    if (data != undefined) {
      console.log(data.orders);
      setValues({
        name: data.name,
        content: data.content,
        media: data.media,
        data: data.data,
        // slider: data.slider,
        btnUrl: data.btnUrl,
        mediaUrl: data.mediaUrl,
        orders: data.orders,
      });
      if (data.media != undefined)
        setMedia(data.media.id)
    }

  }, [data])

  useEffect(() => {
    // console.log(media);
    if (media != undefined) {
      setValues({
        ...values,
        media: `/api/media_objects/${media}`
      });

      const response = AxiosInstance.get(`/media_objects/${media}`).then((response) => {
        if (response.status == 200) {
          setMediaUrl(response.data.contentUrl)
        }
      });
    }
  }, [media]);
  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    setValues({
      ...values,
      [name]: type === "number" ? parseInt(value, 10) : value
    });
  };

  const handleInputInfodata = (e) => {
    const { name, value, type } = e.target;
    let rowkey = parseInt(e.target.attributes.rowkey.value);
    setValues((prevInfoData) => { return {...prevInfoData, data: prevInfoData.data.map((item, index) => index === rowkey ? { ...item, [name]: value } : item)} });
  };

  const addData = () => {
    setValues({
      ...values, data: [...values.data, { key: '',value: '',}]
    });
  }

  const delData = (key) => {
    setValues((prevInfoData) =>{ return {...prevInfoData, data: prevInfoData.data.filter((data, index) => { return index !== key; })}});
  }
  
  /** Відправка форми */
  const handleSubmit = event => {
    event.preventDefault();
    if (editorRef.current) { 
    const allValues = {...values, content: editorRef.current.getContent()};

    if (data == undefined) {
      // parent_id
      allValues['slider'] = `/api/sliders/${parent_id}`;
      if (allValues.media == '')
        delete allValues.media;

      const response = AxiosInstance.post('/slides', allValues).then((response) => {
        if (response.status == 201) {
          // actionShowModal(setShowModal(!rez), getItems()); перший параметр (закрити модальне вікно), другий - оновити дані)
          actionShowModal(false, true);
          // navigate(`/menu/update/${response.data.id}`);
        }
      });
    } else {
      const response = AxiosInstance.put('/slides/' + data.id, allValues).then((response) => {
        if (response.status == 200) {
          // actionShowModal(setShowModal(!rez), getItems()); перший параметр (закрити модальне вікно), другий - оновити дані)
          actionShowModal(false, true);
        }
        // if(response.status == 201){
        //   navigate(`/menu/update/${response.data.id}`);
        // }
      });
    }
    // const response = AxiosInstance.put('/menus/' + data.id, values);
  }
    // }
  }
  
  return (
    <>
      {(mediaUrl && mediaUrl != undefined) ? <img src={process.env.REACT_APP_SERVER_URL + mediaUrl} /> : ''}
      <MediaFrom type="category" setNewMediaId={setMedia} toastifySuccess={toastifySuccess} />
      <CForm onSubmit={handleSubmit}>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
          <CFormInput type="text" name="name" value={values.name} onChange={handleInputChange} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Контент</CFormLabel>
            <Editor
              apiKey={apiKey}
              onInit={(evt, editor) => editorRef.current = editor}
              initialValue={(data != undefined) ? values.content : ''}
              init={{
                height: 300,
                menubar: 'edit view insert format',
                plugins: [
                  'advlist','autolink',
                  'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks','fullscreen',
                  'insertdatetime','media','table', 'help', 'code'
                ],
                toolbar: 'undo redo | fontfamily fontsize | fontselect | bold italic backcolor | ' +
                  'alignleft aligncenter alignright alignjustify | ' +
                  'bullist numlist checklist outdent indent | removeformat | link media a11ycheck code table help',
                content_style: 'body { font-family:FixelDisplayRegular; font-size:14px }',
                font_family_formats: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Fixel Display Regular=fixelDisplayRegular; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
              }}
            />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Посилання для  кнопки</CFormLabel>
          <CFormInput type="text" name="btnUrl" value={values.btnUrl} onChange={handleInputChange} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Посилання для  медіа</CFormLabel>
          <CFormInput type="text" name="mediaUrl" value={values.mediaUrl} onChange={handleInputChange} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Сортування</CFormLabel>
          <CFormInput type="number" name="orders" value={values.orders} onChange={handleInputChange} />
        </div>
        <div className="mb-3">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Додаткові поля</Accordion.Header>
            <Accordion.Body className="p-3">
              {
                values.data.map((val, index) => {
                  if (val != undefined)
                    return (
                      <div className="row mt-1" key={index}>
                        <div className="col">
                          <CFormInput type="text" name="key" value={val.key} rowkey={index} onChange={handleInputInfodata} 
                          />
                        </div>
                        <div className="col">
                          <CFormInput type="text" name="value" value={val.value} rowkey={index} onChange={handleInputInfodata} 
                          />
                        </div>
                        <div className="col-1">
                          <CButton color="danger" variant="outline" onClick={() => { delData(index) }}>
                            <CIcon icon={cilTrash} customClassName="nav-icon" height={20} />
                          </CButton>
                        </div>
                      </div>
                    )
                })
              }
              <div className="row">
                <div className="col d-flex justify-content-center">
                  <Button variant="secondary" onClick={() => { addData() }} className="mb-2 mt-2">+</Button>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        </div>
        <CButton type="submit" color="success">Submit</CButton>
      </CForm>
    </>
  );
}

export default SlideForm;