import React, { useEffect, useState } from 'react';
import { CCard, CCardBody, CCardHeader, CCol, CRow, CButton, CFormCheck, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CFormSelect } from '@coreui/react';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useLoaderData } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import AxiosInstance from 'src/components/Axios';
import { cilPen, cilPlus, cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import PermissionsNoPage, { Permissions } from "src/components/Permissions";
import useOpenAccordion from 'src/components/store/useOpenAccordion';

const urlApi = '/faqs';
const urlRouter = '/faq';

const page = 1;
const postPerPage = 0;
const pageSize = 20;

export async function LoaderFaq({ params }) {
  // let res = await AxiosInstance.get(`${urlApi}?main=true&page=${page}`).then((response) => {
  let res = await AxiosInstance.get(`${urlApi}?page=${page}`).then((response) => {
    return response;
  });
  return res;
};


const Faq = ({ user_data }) => {
  if (!Permissions(user_data, 'Faq', 'VIEW_FAQ')) {
    return <PermissionsNoPage />;
  }
  const { data } = useLoaderData();
  const [rows, setRows] = useState(false);
  const [rowCountState, setRowCountState] = React.useState(0);
  const [categories, setCategories] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState('');
  // Викликає popup з власного компонента
  const popup = MyToastify();
  // Текст для popup
  const popupTextSuccess = "Faq видалено";

  // Відслідковувати стартове значення стану акордиону в залежності від розширення екрану
  const activeAccordionItem = useOpenAccordion();
  const activeItem = activeAccordionItem.activeItem;

  // Оновлення таблиці якщо фільтр змінився
  useEffect(() => {
    getRowsTable();
  }, [categoryFilter])

  useEffect(() => {
    AxiosInstance.get('/categories?type=faq').then(response => {
      const data = response.data['hydra:member'].map(category => {
        return {
          label: category.name,
          value: category.id
        }
      })
      setCategories(data);
    })
  }, [])

  if (!rows) {
    setRowCountState(data['hydra:totalItems'])
    setRows(data['hydra:member']);
  }

  const getRowsTable = (p = 1) => {
    // AxiosInstance.get(`${urlApi}?main=true&page=${p}`).then((response) => {
    let request = ``;
    if (categoryFilter != '') {
      request += `&category.id=${categoryFilter}`;
    };
    AxiosInstance.get(`${urlApi}?page=${p}${request}`).then((response) => {
      setRows(response.data['hydra:member']);
    });
  };

  const handlePageChange = (page) => {
    getRowsTable(page + 1);
  }

  const handleFilterReset = () => {
    setCategoryFilter('');
  }

  function Deleted(row) {
    confirmAlert({
      title: 'Ви впевнені що хочите видалити цей пункт ?',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Так',
          onClick: () => {
            const response = AxiosInstance.delete(`${urlApi}/${row.id}`).then((response) => {
              // Popup про успішні зміни
              popup.toastifySuccess(popupTextSuccess);
              getRowsTable();
            });
          }
        },
        {
          label: 'Ні',
          onClick: () => {
            return;
          }
        }
      ]
    });
  };

  // console.log("params", params);

  const columns = [
    { field: 'name', headerName: 'Назва', width: 200, },
    { field: 'contentShort', headerName: 'Короткий опис', width: 300, },
    {
      field: 'active', headerName: 'Активний', width: 100,
      renderCell: (params) => {
        return <CFormCheck id="formCheck" label="" checked={(params.row.active != 1) ? false : true} disabled />;
      }
    },
    {
      field: 'category', headerName: 'Категорія', width: 300,
      renderCell: (params) => {
        return <>
          {params.row.category.map(cat => {
            return <span key={cat.id} className='category-span'>{cat.name}</span>
          })}
        </>
      }
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 70,
      renderCell: (params) => {
        return <Link to={`${urlRouter}/update/${params.row.id}`}><CButton color="dark" variant="outline"><CIcon icon={cilPen} customClassName="nav-icon" height={20} /></CButton></Link>;
      }
    },
    {
      field: "delete",
      headerName: "Видалити",
      sortable: false,
      renderCell: (params) => {
        return <CButton color="danger" variant="outline" onClick={() => {
          Deleted(params.row)
        }
        }><CIcon icon={cilTrash} customClassName="nav-icon" height={20} /></CButton>;
      }
    },
  ];

  return (
    <>
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />

      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CAccordion key={activeItem} activeItemKey={activeItem} flush>
              <CAccordionItem itemKey={1}>
                <CAccordionHeader><strong>Фільтр</strong></CAccordionHeader>
                <CAccordionBody>
                  <CRow className="my-3 mx-2">
                    <CCol md={9} className="me-auto">
                      <CRow>
                        <CCol md={4} className='filter-field'>
                          <CFormSelect
                            aria-label="Default select"
                            onChange={(e) => { setCategoryFilter(e.target.value) }}
                            // defaultValue={categoryFilter}
                            value={categoryFilter}
                            required
                            options={[{ label: 'Всі категорії', value: '' }, ...categories]}
                          />
                        </CCol>
                        <CCol md={4} className='filter-field'>
                          <p className='pt-2'>
                            <Link to={'/category/create'}>Створити категорію</Link>
                          </p>
                        </CCol>
                      </CRow>
                    </CCol>
                    <CCol md={3} style={{ textAlign: "end" }}>
                      {/* <CButton className="me-2" type="submit" color="secondary" onClick={handleFilterSearch} disabled={disabled}>Пошук</CButton> */}
                      <CButton type="submit" color="secondary" onClick={handleFilterReset}>X</CButton>
                    </CCol>
                  </CRow>
                </CAccordionBody>
              </CAccordionItem>
            </CAccordion>

            <CCardHeader>
              <strong>FAQ</strong> <small></small>
            </CCardHeader>
            <CCardBody>
              <p className="text-medium-emphasis small">
                <Link to={`${urlRouter}/create`}><CButton color="secondary"><CIcon icon={cilPlus} customClassName="nav-icon" height={15} />Створити</CButton></Link>
              </p>
              <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={30}
                  rowsPerPageOptions={[30]}
                  pagination
                  rowCount={rowCountState}
                  paginationMode="server"
                  onPageChange={handlePageChange}
                />
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Faq;