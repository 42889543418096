import { PropaneTankSharp } from "@mui/icons-material";
import { useEffect, useState } from "react";
// import AxiosInstance from "../Axios";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AxiosInstance from "src/components/Axios";


const urlApi = '/languages';

const LangugesPanel = ({ data, setLaguage, language, setMyData }) => {
   const navigate = useNavigate();

   // console.log("data", data);
   const [languagesList, setLaguagesList] = useState([]);
   // const [language, setLaguage] = useState('');
   // const [language, setLaguage] = useState((data != undefined)? data.language.key: 'ua');
   const [translations, setTranslations] = useState((data != undefined && data.translations != undefined) ? data.translations : false);
   //флаг для search
   let search = true
   const getLangs = () => {
      AxiosInstance.get(`${urlApi}?page=1`).then((response) => {
         setLaguagesList(response.data['hydra:member'])
      })
   }
   //зак
   useEffect(() => {
      setLaguage((data != undefined && data.language != undefined) ? data.language.key : 'ua');
      setTranslations(prev => data.translations)
      getLangs();
   }, [data]);



   const CreateTranslate = (e, l) => {

      const data_response = {
         name: '',
         type: data.type
         // category: data.category
         // ...(data.category?.length > 0 ? { category: [...data.category.map(category => `/api/categories/${category.id}`)] } : {})
         // ...(data.mainMedia ? { mainMedia: `/api/media_objects/${data.mainMedia.id}` } : {})
         // ...(data.slides?.length > 0 ? { slides: [...data.slides.map(slide => `/api/slides/${slide.id}`)] } : {})
      };
      data_response['language'] = `/api/languages/${l.id}`;
      data_response['translation'] = `/api/menus/${data.id}`;
      const response = AxiosInstance.post(`/menus`, data_response).then((response) => {
         console.log('response', response)
         if (response.status === 201) {

            return navigate(`/menu/update/${response.data.id}`);
         }
      }).catch((error) => {
         console.error("Error creating translation:", error);
      });

   }

   // console.log('data', data)
   // console.log('languageList', languagesList)
   return (
      <div>
         {/* <div>: </div> */}
         <div>
            <ul>
               {
                  (data != undefined && data.translation != undefined && data.translation && data.translation.id != undefined) ?
                     <>
                        <h4>Переклад: {language}</h4>
                        <Link to={`/menu/update/${data.translation.id}`}>Повернутися до UA</Link>
                     </>
                     :
                     <>
                        <h4>Переклад: {language}</h4>
                        {/* <li className='btn btn-secondary text-white'>{language}</li> */}
                        {languagesList.map((l) => {
                           // let search = true;
                           search = true
                           // console.log('translations', translations);
                           if (l.key != language) {
                              // if (translations !== false) {
                              if (translations?.length > 0) {
                                 let t = translations.filter(t => t.language.key === l.key)
                                 if (t.length > 0) {
                                    // console.log('search', search)
                                    return (<Button key={l.id} variant="outline-secondary ml-5" ><Link to={`/menu/update/${t[0].id}`} >{l.key}</Link></Button>);
                                 }
                                 else
                                    search = false;

                              } else {
                                 search = false;

                              }
                           }
                           if (!search) {
                              return (<Button key={l.id}
                                 variant="outline-secondary mr-5"
                                 onClick={(e) => { CreateTranslate(e, l) }}
                              >
                                 додати  <b>{l.key}</b>
                              </Button>);
                           }

                        })}
                     </>
               }
            </ul>
         </div>

      </div>


   );

}

export default LangugesPanel;