import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import AxiosInstance from "../Axios";
import ScanningsForm from './ScanningsForm';
import { Card } from "react-bootstrap";
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import BasicModal from 'src/components/Modal';
import { CButton } from "@coreui/react";
import CIcon from '@coreui/icons-react';
import { cilTrash } from '@coreui/icons';
import { confirmAlert } from "react-confirm-alert";

const ScanningsList = ({id}) => {

    const [scannings, setScannings] = useState([]);

    const [showModalItemUpdate, setShowModalItemUpdate] = useState(false);

    const [showModalItemNew, setShowModalItemNew] = useState(false);

    const [selectedData, setSelectedData] = useState(null); // Вибраний модуль для редагування

    const popup = MyToastify();

    const actionShowModal = (rez) => {
        setShowModalItemNew(rez);
    };

    const actionShowModalItemNew = (rez) => {
        setShowModalItemNew(rez);
        if (!rez) {
            getItems();
        }
    };

    
    const actionShowModalItemUpdate = (rez) => {
        setShowModalItemUpdate(rez);
        if (!rez) {
            getItems();
            setSelectedData(rez);
        };
    };

    const getItems = () => {
        AxiosInstance.get(`/scannings?pagination=false&object.id=${id}`).then((response) => {
            // console.log('/page_infos get response', response);
            if (response.status == 200)
                setScannings(response.data['hydra:member']);
        });
    };

    useEffect(() => {
        if (id != undefined) {
            getItems();
        };
    }, [id]);

    function Deleted(id) {
        confirmAlert({
            title: 'Ви впевнені що хочите видалити цей пункт ?',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Так',
                    onClick: () => {
                        AxiosInstance.delete('/scannings/' + id).then(() => {
                            getItems();
                        });
                    }
                },
                {
                    label: 'Ні',
                    onClick: () => {
                        return;
                    }
                }
            ]
        });
    };

    // Відкривати вибраний модуль для редагування
    useEffect(() => {
        if (selectedData) {
            setShowModalItemUpdate(true);
        };
    }, [selectedData]);

    return (
        <Card className="mt-5" >
            <Card.Header>Дані сканування</Card.Header>
            <Card.Body>

                {scannings.length > 0 && scannings.map((scan) => {
                    return (
                        <Card key={scan.id} body className="info-list">
                            <span onClick={() => setSelectedData(scan)} style={{ cursor: "pointer" }} title="Редагувати">{scan.year}</span>
                            <div>
                                <CButton color="danger" variant="outline" onClick={() => { Deleted(scan.id) }}>
                                    <CIcon icon={cilTrash} customClassName="nav-icon" height={20} />
                                </CButton>
                            </div>
                        </Card>
                    );
                })}


                <hr />
                <BasicModal
                    show={showModalItemNew}
                    // actionShowModal={actionShowModalItemNew}
                    actionShowModal={actionShowModal}
                    title={`Додати сканування`}
                    btn_name={<>+ додати</>}
                    content={
                        <ScanningsForm
                            // getItems={getItems}
                            toastifySuccess={popup.toastifySuccess} parent_id={id} actionShowModal={actionShowModalItemNew} />
                    }
                />
                {/* Модальне вікно для редагування модуля */}
                    {(selectedData && showModalItemUpdate) &&
                    <div style={{display: 'none'}}> {/* приховати кнопку відкритого модуля */}
                        <BasicModal
                            show={showModalItemUpdate}
                            actionShowModal={actionShowModalItemUpdate}
                            title={`Оновити модуль`}
                            btn_name={selectedData.key}
                            btn_color="light"
                            content={
                                <ScanningsForm
                                    // getItems={getItems}
                                    toastifySuccess={popup.toastifySuccess} parent_id={id} actionShowModal={actionShowModalItemUpdate} data={selectedData} />
                            }
                        />
                    </div>
                }
            </Card.Body>

        </Card>
    );
}

export default ScanningsList