import { useState, useEffect } from "react";
import AxiosInstance from "../Axios";
import 'react-toastify/dist/ReactToastify.css';
import { CButton, CFormSelect } from "@coreui/react";
import { Carousel } from 'react-responsive-carousel';
import { Link } from "react-router-dom";
import { cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import GalleryObjectForm from "./GalleryObjectForm";
import {  BsSortNumericDown } from 'react-icons/bs';
import { sortObj } from "../store/sortObj";
import { unique } from "src/REDUX/store";

const GalleryObject = ({ dataId, currentGalleries, popup, type }) => {

const user = localStorage.getItem('user_data');
const [galleryList, setGalleryList] = useState([])
const [gallery, setGallery] = useState(false);
const [galleryObject, setGalleryObject] = useState(currentGalleries);
const [isCreate, setIsCreate] = useState(false);
const [objGalleryData, setObjectGalleryData] = useState();

  useEffect(()=>{
    AxiosInstance.get(`/galleries?pagination=false`).then((responce) => {
        if (responce.status == 200) {
            const gallerySelect = responce.data['hydra:member'].map((gallery)=>{
                return {label: gallery.name, value: gallery.id, data: gallery}
            })
            setGalleryList(unique([{label: 'Оберіть галерею', value: false}, ...gallerySelect], 'label'));
        }
      });
  },[objGalleryData])

  const postGalleryItem = async (data, parentGalery)=>{
    const article = {
      name: data.name,
      content: data.contentUrl,
      gallery: `/api/galleries/${parentGalery}`,
      media: data.media["@id"],
      urlPhoto: data.urlPhoto,
      urlVideo: data.urlVideo
    };
    const galleryItem = await AxiosInstance.post('/gallery_items', article);
    return galleryItem.data;
}

  function addGallery() {
    if (gallery) {
      const selectGallery = galleryList.find(gall => gall.value == gallery);
      // параметри для створення нової галереї по вибраному шаблону
      const article = {
          name: selectGallery.data.name,
          // object: `/api/objects/${dataId}`,
          content: selectGallery.data.content,
          sort: selectGallery.data.sort
      }
      AxiosInstance.post('/galleries', article).then(response => {
        // відправка запитів на стоврення нових galleryItem для новоствореної галереї по вибраному шаблону
          const requests = selectGallery.data.galleryItems.map(item => postGalleryItem(item, response.data.id))
          Promise.all(requests).then((respItems) =>{
            setGalleryObject([...galleryObject, {...response.data, galleryItems: respItems}]);
            popup.toastifySuccess('Галерею додано');
          })
      })
    }
  }

  function postGallery() {
    const galleries =  galleryObject.map(gallery => gallery['@id']);
      AxiosInstance.put(`/${type}/${dataId}`, {galleries: galleries}).then((response)=>{
        popup.toastifySuccess('Галерею збережено');
      });
  }

  function removeGallery(id) {
    const filtredGalleries = galleryObject.filter(gallery => gallery.id !== id);
    setGalleryObject(filtredGalleries);
  }

  function createGallery() {
    if (!isCreate) {
      setObjectGalleryData();
    }
    setIsCreate(!isCreate);
  }

  function updateGallery (e, id) {
    // setIsCreate(true);
    e.preventDefault()
    if(id !== undefined) {
      const currentUpdate = galleryObject.find(gallery => gallery.id === id);
      setObjectGalleryData(currentUpdate);
      setIsCreate(true);
    }
  }

  return (
    <div className="gallery-object">
    <div className="mb-3 d-flex align-items-center gap-3">
        <CFormSelect
            aria-label="gallery-object__select"
            onChange={(e) => setGallery(e.target.value)}
            defaultValue={false}
            required
            options={galleryList}
            style={{maxWidth: '500px', height: '100%'}}
          />
          <button type="button" onClick={addGallery} className="mb-2 mt-2 btn btn-secondary">+</button>
          <button type="button" onClick={createGallery} className="mb-2 mt-2 btn btn-secondary">{!isCreate ? "Створити галерею" : "Повернутись"}</button>
    </div>
    {isCreate 
      ? <GalleryObjectForm 
          user={user} 
          data={objGalleryData} 
          setData={setObjectGalleryData} 
          popup = {popup}
          setGalleryObject = {setGalleryObject}
          galleryObject = {galleryObject}
        />
      : <>{galleryObject?.length > 0 &&
          <div className="gallery-object__inner mb-4">
              {sortObj(galleryObject, 'sort').map(((gallery, index) => {
                  return  <div className="gallery-object__item">
                          <div className="gallery-object__btn">
                            <div>
                              <Link className="btn btn-secondary" onClick={(e) => {updateGallery(e, gallery.id)}} target="_blank">Редагувати 🖊</Link>
                              <h4>{gallery.name}</h4>
                            </div>
                            <div>
                              <CButton color="danger" variant="outline" onClick={() => {removeGallery(gallery.id)}}>
                                <CIcon icon={cilTrash} customClassName="nav-icon" height={20} />
                              </CButton>
                              <small className='SortPunkt'><BsSortNumericDown /> - {gallery.sort}</small>
                            </div>
                          </div>
                          <Carousel
                              className="me-3"
                              style={{ height: '300px', width: 'auto' }}
                              showArrows={true}
                              width={500}
                              showStatus={false}
                              key={index}
                              // selectedItem={indexMainMedia}
                          >
                          {gallery.galleryItems.map(item => {
                              return <img key={item.id} src={process.env.REACT_APP_SERVER_URL + item.media?.contentUrl} alt="gallery" />
                          })}    
                          </Carousel>
                  </div>
              }))}
          </div>}
          <CButton type="submit" onClick={postGallery} color="success">Зберегти</CButton>
        </>}
    </div>
  );
};

export default GalleryObject;