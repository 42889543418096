import {useState } from "react";
import {
  CForm,
  CFormLabel,
  CFormInput,
  CButton,
  CFormSelect
} from '@coreui/react';

const ModelsObjectEdit = ({ toastifySuccess, data, indexUpdate, actionShowModal, setModelsObject  }) => {
  
  const [type, setType] = useState((data != undefined) ? data.type : '' );
  const [sort, setSort] = useState((data != undefined) ? data.sort : 0);

  const handleSubmit = event => {
    event.preventDefault();
    if (data != undefined && indexUpdate !== undefined) {
        setModelsObject((prevData) => prevData.map((item, index) => index === indexUpdate ? { ...item, type: type, sort: sort === '' ? 0 : sort} : item));
        toastifySuccess('Модель оновлено');
        actionShowModal(false, true);
     } 
    }

  return (<>
    <CForm onSubmit={handleSubmit}>
      <div className="mb-3 mt-3">
        <CFormSelect
                aria-label="Default select example"
                onChange={(e) => setType(e.target.value)}
                defaultValue={type}
                required
                options={[
                  { label: 'Тип моделі', value: '' },
                  { label: 'sketchfab', value: 'Sketchfab' },
                  { label: 'nira', value: 'Nira' },
                  { label: 'other', value: 'Other' }
                ]}
                style={{maxWidth: '500px', height: '100%'}}
            />
      </div>
      <div className="mb-3">
        <CFormLabel htmlFor="exampleFormControlInput1">Сортування</CFormLabel>
        <CFormInput type="number" id="exampleFormControlInput1" value={sort} onChange={(e) => setSort(parseInt(e.target.value) || '')} />
      </div>
      <CButton type="submit" color="success">Зберегти</CButton>
    </CForm>
  </>);
}

export default ModelsObjectEdit;