import { CButton, CForm, CFormInput, CFormLabel } from '@coreui/react'
import React, { useState } from 'react'
import FormAttributes from '../attributes/FormAttributes';
import { Editor } from '@tinymce/tinymce-react';
import { useRef } from 'react';
import AxiosInstance from '../Axios';
import { useEffect } from 'react';
import Select from 'react-select';

const initialValues = {
    // id: "",
    // name: "",
    mcipuId: "",
    year: 0,
    description: "",
}
// Ключ для Editor
const apiKey = process.env.REACT_APP_TINYMCE_API_KEY;

const ScanningsForm = ({toastifySuccess, data, actionShowModal, parent_id}) => {
  
  const [values, setValues] = useState(initialValues);
  const [attributeItems, setAttributeItems] = useState([]);
  const editorRef = useRef(null);
  const [attrCategoryId, setAttrCategoryId] = useState();

  const [partners, setPartners] = useState((data != undefined)? data.partners.map(partner =>{return {label: partner.name, value: partner.id}}) : []);
  const [financed, setFinanced] = useState((data != undefined)? data.financed.map(finance =>{return {label: finance.name, value: finance.id}}) : []);
  const [accountOptions, setAccountOptions] = useState([]);
  const [projects, setProjects] = useState((data != undefined)? data.projects.map(project =>{return {label: formatShortName(project.shortName) || project.name, value: project.id}}) : []);
  const [projectOptions, setProjectOptions] = useState([]);

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    setValues({
      ...values,
      [name]: type === "number" ? (parseInt(value, 10) || "") : value
    });
  };

  function formatShortName(name) {
    return name?.replace(/<\/?h1[^>]*>/g, '');
  }

  useEffect(() => {
    if (data != undefined) {
      setValues({
        mcipuId: data.mcipuId,
        year: data.year,
        description: data.description,
      });
    }
  }, [data])

  useEffect(()=>{
    AxiosInstance.get(`/categories`, { headers: { 'accept': 'application/json' }, params: {
      'g[0]': 'cat:read',
    } }).then((response) => {
        const [data] = response.data.filter(cat => (cat.type === 'attributes' && cat.slug === 'scan_attr'));
        if(data !== undefined) {
          setAttrCategoryId(data.id)
        }
    });
  },[])

  useEffect(()=>{
    AxiosInstance.get('/accounts?pagination=false').then((response) => {
      const data = response.data['hydra:member'];
      const accountOptions = data.map(account => {
        return {label: account.name, value: account.id}
      })
      setAccountOptions(accountOptions);

    }).catch((err) => console.log(err));
  }, [])

  useEffect(()=>{
    AxiosInstance.get('/projects?pagination=false').then((response) => {
      const data = response.data['hydra:member'];
      const projectOptions = data.map(project => {
        return {label: formatShortName(project.shortName) || project.name, value: project.id}
      })
      setProjectOptions(projectOptions);

    }).catch((err) => console.log(err));
  }, [])

  const handleSubmit = event => {
    event.preventDefault();
    if (editorRef.current) {

      const article = {
        ...values,
        year: values.year !== "" ? values.year : 0,
        object: `/api/objects/${parent_id}`,
        attributeItems: attributeItems,
        description: editorRef.current.getContent(),
        partners: partners.map(partner => `/api/accounts/${partner.value}`),
        financed: financed.map(finance => `/api/accounts/${finance.value}`),
        projects: projects.map(project => `/api/projects/${project.value}`),
      };
      if (data !== undefined) {
        AxiosInstance.put('/scannings/' + data.id, article).then(()=>{
          actionShowModal(false);
          toastifySuccess('Дані оновлено');
        });
      }
      else {
        AxiosInstance.post('/scannings', article).then((response) => {
            if (response.status == 201) {
              actionShowModal(false);
              toastifySuccess('Дані збережено');
            }
        });
      }
    }
  }

  return (
    <CForm onSubmit={handleSubmit} className="mt-3 scaning-form">
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">McipuId</CFormLabel>
          <CFormInput type="text" name="mcipuId" value={values.mcipuId} onChange={handleInputChange} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Рік</CFormLabel>
          <CFormInput type="number" name="year" value={values.year} onChange={handleInputChange} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput2">Партнери</CFormLabel>
          <Select
            value={partners}
            isMulti
            name="colors"
            options={accountOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(value) => {
              setPartners(value);
            }}
          />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput2">Фінансування</CFormLabel>
          <Select
            value={financed}
            isMulti
            name="colors"
            options={accountOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(value) => {
              setFinanced(value);
            }}
          />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput2">Проекти</CFormLabel>
          <Select
            value={projects}
            isMulti
            name="colors"
            options={projectOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(value) => {
              setProjects(value);
            }}
          />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Опис</CFormLabel>
            <Editor
                apiKey={apiKey}
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue={values.description}
                init={{
                  height: 300,
                  menubar: 'edit view insert format',
                  plugins: [
                    'advlist','autolink',
                    'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks','fullscreen',
                    'insertdatetime','media','table', 'help', 'code'
                 ],
                  toolbar: 'undo redo | fontfamily fontsize | fontselect | bold italic backcolor | ' +
                    'alignleft aligncenter alignright alignjustify | ' +
                    'bullist numlist checklist outdent indent | removeformat | link media a11ycheck code table help',
                  content_style: 'body { font-family:FixelDisplayRegular; font-size:14px }',
                  font_family_formats: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Fixel Display Regular=fixelDisplayRegular; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
                }}
            />
        </div>
        <div className="scanning-attr mb-3">
            {(data !== undefined && attrCategoryId !== undefined) && <>
            <CFormLabel htmlFor="exampleFormControlInput1">Атрибути</CFormLabel> 
              <FormAttributes 
                dataId={data.id} 
                attributeItems={data.attributeItems}
                setSelectedList = {setAttributeItems} 
                toastifySuccess={toastifySuccess} 
                categorieID = {attrCategoryId}
                type="products" 
                url="/scannings" 
            /></>}
        </div>
        <CButton type="submit" color="success">Зберегти сканування</CButton>
    </CForm>
  )
}

export default ScanningsForm