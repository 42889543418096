import React, { useEffect, useRef, useState } from 'react';
import { useLoaderData, useLocation } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { Editor } from '@tinymce/tinymce-react';
import { useNavigate } from "react-router-dom";
import { CForm, CFormLabel, CFormInput, CButton, CFormTextarea, CFormCheck } from '@coreui/react';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import FaqsCategories from 'src/components/faq/FaqsCategories';
import PermissionsNoPage, { Permissions } from "src/components/Permissions";
import LangugesPanel from './LangugesPanel';

const urlApi = '/faqs';
const urlRouter = '/faq';
// Ключ для Editor
const apiKey = process.env.REACT_APP_TINYMCE_API_KEY;

export async function LoaderFaqUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.pid}`).then((response) => {
      return response;
    });
    return res;
  }
  return false;
};

const formReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value
  }
};

const FaqForm = ({ user_data }) => {
  if (!Permissions(user_data, 'Faq', 'EDIT_FAQ')) {
    return <PermissionsNoPage />;
  }
  const navigate = useNavigate();
  const { data } = useLoaderData();
  const [name, setName] = useState((data != undefined) ? data.name : '');
  const [contentShort, setContentShort] = useState((data != undefined) ? data.contentShort : '');
  const [active, setActive] = useState((data != undefined) ? data.active : '0');
  const [top, setTop] = useState((data != undefined) ? data.top : '0');
  const [language, setLaguage] = useState((data != undefined) ? data.language?.key : 'ua');
  const editorRef = useRef(null); // data.contentFull

  // Викликає popup з власного компонента
  const popup = MyToastify();
  // Текст для popup
  const popupTextPutSuccess = "FAQ оновлено";
  const popupTextPostSuccess = "FAQ створено";
  const popupTextWarning = "Error";

  // Сьогоднішня дата
  const todayDate = new Date();
  const date = todayDate.getDate() + "-" + (todayDate.getMonth() + 1) + "-" + todayDate.getFullYear();

  // Для checkbox при ініціалазації
  const initActive = (active != 1) ? false : true;
  const initTop = (top != 1) ? false : true;
  // Зміна checkbox
  const [changeActive, setChangeActive] = useState(initActive);
  const [changeTop, setChangeTop] = useState(initTop);

  useEffect(() => {
    if (data != undefined) {
      setName(data.name)
      setContentShort(data.contentShort)
      setActive(data.active)
      setTop(data.top)
      // console.log('data', data);
    }
  }, [data]);

  // Для зміни значення checkbox для поля "Актикний"
  useEffect(() => {
    changeActive ? setActive(1) : setActive(0);
  }, [changeActive]);

  // Для зміни значення checkbox для поля "Хіти продаж"
  useEffect(() => {
    changeTop ? setTop(1) : setTop(0);
  }, [changeTop]);

  const handleSubmit = event => {
    event.preventDefault();
    if (editorRef.current) {
      // console.log(editorRef.current.getContent());
      const article = {
        name: name,
        date: date,
        contentShort: contentShort,
        contentFull: editorRef.current.getContent(),
        active: active.toString(),
        top: top.toString()
      };

      // console.log('article', article);

      if (data != undefined) {
        article.language = data.language ? `/api/languages/${data.language.id}` : `/api/languages/1`;
        AxiosInstance.put(`${urlApi}/${data.id}`, article).then((response) => {
          console.log("put response", response);
          if (response.status === 200) {
            // Popup про успішні зміни
            popup.toastifySuccess(popupTextPutSuccess);
            return navigate("/faq/update/" + response.data.id);
          } else {
            alert("Error");
            // Popup попередження
            popup.toastifyWarning(popupTextWarning);
          };
        });
      } else {
        article.language = `/api/languages/1`;
        AxiosInstance.post(`${urlApi}`, article).then((response) => {
          console.log("post create response", response);
          if (response.status === 201) {
            // Popup про успішні зміни
            popup.toastifySuccess(popupTextPostSuccess);
            return navigate("/faq/update/" + response.data.id);
          }
        });
      }
    }
  };

  return (
    <>
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />
      <div className='col d-flex justify-content-end'>
        {data !== undefined &&
          <LangugesPanel data={data}
            // setMyData={setMyData}
            setLaguage={setLaguage}
            language={language} />}
      </div>
      <CForm onSubmit={handleSubmit}>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className="mb-3">
          <CFormCheck id="exampleFormControlInput8" label="Активний" checked={initActive} onChange={() => setChangeActive(!changeActive)} />
        </div>
        {/* <div className="mb-3">
          <CFormCheck id="exampleFormControlInput8" label="Хіт продаж" checked={initTop} onChange={() => setChangeTop(!changeTop)} />
        </div> */}
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlTextarea1">Короткий опис</CFormLabel>
          <CFormTextarea id="exampleFormControlTextarea1" rows={3} value={contentShort} onChange={(e) => setContentShort(e.target.value)}></CFormTextarea>
        </div>
        <div className="mb-3">
          <Editor
            apiKey={apiKey}
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={(data != undefined) ? data.contentFull : ''}
            init={{
              height: 600,
              menubar: 'edit view insert format',
              plugins: [
                'advlist', 'autolink',
                'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks', 'fullscreen',
                'insertdatetime', 'media', 'table', 'help', 'code'
              ],
              toolbar: 'undo redo | fontfamily fontsize | fontselect | bold italic backcolor | ' +
                'alignleft aligncenter alignright alignjustify | ' +
                'bullist numlist checklist outdent indent | removeformat | link media a11ycheck code table help',
              content_style: 'body { font-family:FixelDisplayRegular; font-size:14px }',
              font_family_formats: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Fixel Display Regular=fixelDisplayRegular; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
            }}
          />
        </div>

        <div className="mb-3">
          <CButton type="submit" color="success">Зберегти</CButton>
          <Link to={urlRouter} className="ms-3"><CButton type="submit" color="secondary">Відміна</CButton></Link>
        </div>
      </CForm>

      <hr />
      {/* {(MyData != undefined) ? <AttributesCategories attribute_id={MyData.id} toastifySuccess={popup.toastifySuccess} toastifyWarning={popup.toastifyWarning} /> : ''} */}
      {(data != undefined) ? <FaqsCategories faq_id={data.id} toastifySuccess={popup.toastifySuccess} toastifyWarning={popup.toastifyWarning} /> : ''}
    </>
  );
};

export default FaqForm;