import React, { useRef, useState, useEffect, useDebugValue } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import AddHeaderSlider from 'src/components/headers/AddHeaderSlider';
import { CForm, CFormLabel, CFormInput, CButton, CCard, CCardBody, CCardHeader, CCol, CRow, } from '@coreui/react';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import PageInfoList from 'src/components/page-info/PageInfoList';
import PermissionsNoPage, { Permissions } from "src/components/Permissions";
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import GalleryObject from 'src/components/gallery/GalleryObject';
import LangugesPanel from './LangugesPanel';

const tokenStr = localStorage.getItem('user');
const header = { headers: { "Authorization": `Bearer ${tokenStr}` } };

const urlApi = '/pages';
const urlRouter = '/site-pages';

export async function LoaderPagesUpdate({ params }) {
  if (Object.keys(params).length != 0 && params.id != undefined) {
    let res = await AxiosInstance.get(`${urlApi}/${params.id}`).then((response) => {
      response['type'] = (params.type != undefined) ? params.type : "page";
      console.log(response);
      return response;
    });
    return res;
  };

  return { type: (params.type != undefined) ? params.type : 'page' };
};


const pagesForm = ({ user_data }) => {
  if (!Permissions(user_data, 'Pages', 'EDIT_PAGES')) {
    return <PermissionsNoPage />;
  }
  const navigate = useNavigate();
  const { data, type } = useLoaderData();
  const [name, setName] = useState((data != undefined) ? data.name : '');
  const [pageType, setPageType] = useState((type != undefined) ? type : 'page');
  const [synonym, setSynonym] = useState((data != undefined) ? data.synonym : '');
  const [mainImg, setmainImg] = useState((data != undefined) ? data.images : '');
  const [sliders, setSliders] = useState((data != undefined && data.slider != undefined) ? data.slider.id : false);
  const [language, setLaguage] = useState('');

  const editorRef = useRef(null);
  const [myData, setMyData] = useState((data != undefined) ? data : false);

  const popup = MyToastify();
  // Ключ для Editor
  const apiKey = process.env.REACT_APP_TINYMCE_API_KEY;

  // Викликає popup з власного компонента
  // Текст для popup
  const popupTextPostSuccess = "Сторінку створено";
  const popupTextPutSuccess = "Сторінку змінено";
  useEffect(() => {
    setMyData(data)
    setPageType(data.type || 'page')
    setSynonym(data.synonym)
    if (data.images) {
      setmainImg(data.images)
    }
    if (data.slider) {
      setSliders(data.slider.id)
    }
    setName(data.name)
  }, [data]);
  const handleSubmit = event => {
    event.preventDefault();
    if (editorRef.current) {
      // console.log(editorRef.current.getContent());
      const article = {
        name: name,
        synonym: synonym,
        type: pageType,
        content: editorRef.current.getContent(),
        active: 'on',
        images: mainImg,
      };
      if (data != undefined) {
        article.language = data.language ? `/api/languages/${data.language.id}` : `/api/languages/1`;
        AxiosInstance.put(`${urlApi}/${data.id}`, article).then((response) => {
          if (response.status === 200) {
            // Popup про успішні зміни
            popup.toastifySuccess(popupTextPutSuccess);
          }
        });
      } else {

        article.language = `/api/languages/1`;
        AxiosInstance.post(urlApi, article).then((response) => {
          // console.log('post pages response', response);
          if (response.status === 201) {
            popup.toastifySuccess(popupTextPostSuccess);
            if (type == undefined || type == 'page' || type == '')
              return navigate(`${urlRouter}/update/${response.data.id}`);
            else
              return navigate(`/p/${type}/${response.data.id}`);
          }
        });
      };
    };
  };


  console.log(data)
  return (
    <>
      <ToastContainer />
      <h2>{name}</h2>
      <hr />
      {/* //зміни */}
      <div className='col d-flex justify-content-end'>
        {data !== undefined && <LangugesPanel data={myData} setMyData={setMyData} set setLaguage={setLaguage} language={language} />}
      </div>
      {/* //зміни */}
      <Tab.Container id="left-tabs-example" defaultActiveKey="info">
        <Row>
          <Col sm={2}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="info">Загальні</Nav.Link>
              </Nav.Item>
              {data !== undefined && <>
                <Nav.Item>
                  <Nav.Link eventKey="gallery">Галерея</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="data">Дані сторінки</Nav.Link>
                </Nav.Item>
              </>}
            </Nav>
          </Col>
          <Col sm={10}>
            <Tab.Content>
              <Tab.Pane eventKey="info">
                <AddHeaderSlider id={(sliders !== false) ? sliders : undefined} newSlider={setSliders} parentUrl={`pages/${data?.id}`} />
                <CForm onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
                    <CFormInput type="text" id="exampleFormControlInput1"
                      value={name}
                      // value={myData?.name}
                      onChange={(e) => setName(e.target.value)} autoComplete="off" />
                  </div>
                  <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Синонім</CFormLabel>
                    <CFormInput type="text" id="exampleFormControlInput2"
                      value={synonym}
                      // value={myData?.synonym}
                      onChange={(e) => setSynonym(e.target.value)} autoComplete="off" />
                  </div>
                  <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Тип</CFormLabel>
                    <CFormInput type="text" id="exampleFormControlInput2"
                      value={pageType}
                      // value={myData?.type}
                      onChange={(e) => setPageType(e.target.value)} autoComplete="off" disabled={(type == undefined || type == 'page') ? false : true} />
                  </div>
                  <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Головне зображення</CFormLabel>
                    <CFormInput type="text" id="exampleFormControlInput3"
                      value={mainImg}
                      // value={myData?.mainImg}
                      onChange={(e) => setmainImg(e.target.value)} autoComplete="off" />
                    <Link to="/media" target='_blanck'>перейти до медіа</Link>
                  </div>
                  <div className="mb-3">
                    <Editor
                      apiKey={apiKey}
                      onInit={(evt, editor) => editorRef.current = editor}
                      initialValue={(data != undefined) ? data.content : ''}
                      init={{
                        height: 500,
                        menubar: 'edit view insert format',
                        plugins: [
                          'advlist', 'autolink',
                          'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks', 'fullscreen',
                          'insertdatetime', 'media', 'table', 'help', 'code'
                        ],
                        toolbar: 'undo redo | fontfamily fontsize | fontselect | bold italic backcolor | ' +
                          'alignleft aligncenter alignright alignjustify | ' +
                          'bullist numlist checklist outdent indent | removeformat | link media a11ycheck code table help',
                        content_style: 'body { font-family:FixelDisplayRegular; font-size:14px }',
                        font_family_formats: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Fixel Display Regular=fixelDisplayRegular; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
                      }}
                    />
                  </div>
                  <CButton type="submit" color="success">Зберегти</CButton>
                </CForm>
              </Tab.Pane>
              {data !== undefined &&
                <>
                  <Tab.Pane eventKey="data">
                    <PageInfoList id={data.id} type="page" />
                  </Tab.Pane>
                  <Tab.Pane eventKey="gallery">
                    <GalleryObject dataId={data.id} popup={popup} type={'pages'} currentGalleries={data.galleries} />
                  </Tab.Pane>
                </>}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>

  );
};

export default pagesForm;
