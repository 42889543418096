import { CFormLabel } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import '../../App.css';
import AxiosInstance from '../Axios';
import Select from 'react-select'
import ReactDOMServer from "react-dom/server";
import dayjs from 'dayjs';

const RelatedPublications = ({ editorRef, popup }) => {

    const [publications, setPublications] = useState([]);
    const [selectedPublication, setSelectedPublication] = useState(0);

    useEffect(()=> {
        AxiosInstance.get('/posts?pagination=false').then(response => {
            const data = response.data["hydra:member"]
            setPublications(data.map((post) => {return {label: post.name, value: post.id, post: post}}))
        })
    }, [])

    function handleRelated() {
        if(selectedPublication.post !== undefined && editorRef !== undefined) {
            const generatePost = getReletedPost(selectedPublication.post);
            editorRef.current.setContent(editorRef.current.getContent() + generatePost);
            popup.toastifySuccess("Публікацію прив'язано");
        }
        else {
            popup.toastifyWarning("Не вибрано публікацію");
        }
    }

    function getReletedPost(post) {
        const media = post.mainMedia?.resizeImagings?.find(resize => resize.size === 230)?.contentUrl || post?.mainMedia?.contentUrl;
        return ReactDOMServer.renderToString (
            <>
                <a href={`/publications/${post.id}`} className={`newsSection__block1 ${post.type}`}>
                    <div className="newsSection__block1__image">
                        <img className="news-image" src={media ? (process.env.REACT_APP_SERVER_URL + media) : "/temp.png"} alt="image" />
                        </div>
                    <div className={`newsSection__block1__content ${post.type}`}>
                        <div className={`news-button ${post.type}`}>
                            {post.type}
                        </div>
                        <div className={`news-textContent ${post.type}`}>
                            <h3 className="news-textContent-h3">
                                {post.name}
                            </h3>
                            <p>
                                {dayjs(post.dateEntered).format("DD MMM YYYY")}
                            </p>
                        </div>
                    </div>
                </a>
            <div></div>
            </>
        )
    }

    return (
        <div className="mb-3 related">
            <CFormLabel htmlFor="exampleFormControlInput1">Пов'язані публікації</CFormLabel>
            <div className='row'>
                <div className="col">
                <Select
                    options={publications}
                    value={selectedPublication}
                    onChange={(value) => setSelectedPublication(value)}
                />
                </div>
                <div className="col">
                    <button type="button" onClick={handleRelated} className="btn btn-secondary"> Додати +</button>
                </div>
            </div>
        </div>
    );
};

export default RelatedPublications;